.main {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    
    &__sidebar {
        position: fixed;
        background: var(--sidebar-background-color);
        color: var(--sidebar-icon-color);    
        text-align: left;
        width: var(--width-sidebar);
        transition: all 0.3s 0s ease-in-out;
    }

    &__content {
        flex: 1;
    }
}

.notifications.circle.unread {
    margin-left: 9px;
    width: 12px;
    height: 12px;
    background-color: var(--error-color);
    border: 1.8px solid #FFFFFF;
    box-sizing: border-box;
    position: absolute;
}