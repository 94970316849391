.card-box {
    .hover-expand {
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        font-size: 0.75rem;
    }

    .MuiButton-outlinedInherit {
        color: #fff;
    }

    i {
        font-size: 0.75rem;
    }

    .footer {
        .i-button {
            color: var(--iron-gray-color);
        }
    }

    .evaluation {
        background-color: transparent !important;
    }

    figure {
        position: relative;
        display: inline-block;
        margin: 0 !important;
        background-color: rgba(0, 0, 0, 0.7);
    }

    figure img {
        display: block;
        max-width: 100%;
        height: auto;
        border: none;
    }

    .box-info .footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--fog-gray-color);
        height: 2.5rem;
        font-size: 0.75rem;

        .sticker {
            min-width: 90px;
            background-color: transparent !important;
            justify-content: flex-end;
            padding-right: 0;
            text-align: end;
        }

        .sticker-idea {
            border-radius: 4px;
            width: 24px;
            height: 24px;
            color: rgba(0, 0, 0, 0.75);
        }

        .sticker-idea.status.just {
            width: auto;
            background-color: transparent;
        }
    }

    .box-info .footer:after {
        display: block;
        clear: both;
    }

    .box-info {
        position: relative;
        clear: both;
        background-color: #fff;
    }

    .content-body {
        padding: var(--card-padding) calc(2px + var(--card-padding)) calc(var(--card-padding)) calc(2px + var(--card-padding));

        .circle {
            border: 1px solid var(--charcoal-color);

            .s-circle {
                color: var(--charcoal-color);
            }
        }
    }

    .content-infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.75);

        .item-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 30px;
            white-space: nowrap;
        }

        .item-title.clickable:hover {
            text-decoration: underline;
            color: var(--primary);
        }

        .publication-date {
            letter-spacing: 0.01em;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
        }
    }

    .box-info .footer {
        padding: 0 var(--card-padding);
    }

    .box-item,
    .box-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
    }

    .rollover:hover:not(.no-scale) .box-photo img {
        transform: scale(1.05);
    }

    .rollover:not(.no-hover):hover .box-photo img {
        opacity: 0.5;
    }

    .rollover img {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
        z-index: 1;
        object-fit: cover;
        transform: translateZ(0);
        -webkit-transition: all 0.4s linear;
        transition: all 0.4s linear;
        background: var(--primary);
    }

    .lazy-loaded {
        opacity: 1;
    }

    .rollover {
        display: block;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    .rollover .hover-item {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    .rollover .box-photo {
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .rollover:hover .hover-item {
        visibility: visible;
        opacity: 1;
        z-index: 2;
        transition-duration: 0.3s;
        transition-delay: 0.2s;
        filter: alpha(opacity=100);
        -moz-opacity: 1;
    }

    .hover-item .bottom {
        width: 100%;
        margin-top: -2.7rem;
        z-index: 2;
        padding-right: 0.8rem;
        position: absolute;
        align-items: center;
    }

    .hover-item {
        padding: var(--card-padding);

        .top.right {
            .activity-count {
                min-width: var(--width-activity-count);
                max-width: var(--width-activity-count);
            }
        }

        .top {
            position: absolute;
            align-items: center;

            .activity-count svg,
            .activity-count {
                font-size: 0.75rem;
            }

            .activity-count {
                padding: 2px 6px;
                height: 24px;
            }

            .circle {
                color: #fff;
                min-width: 30px;
                max-width: 30px;
                height: 30px;
                font-size: 0.75rem;
            }

            span,
            i {
                color: #fff;
            }

            span {
                font-size: 0.875rem;
            }

            &.left,
            &.right {
                display: flex !important;
            }

            &.right {
                margin-top: 2px;
                right: calc(0px + var(--card-padding));
            }
        }

        .middle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}