.circle--text {
  font-size: 11px;
  color: var(--iron-gray-color);
}

.circle {
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
  
  align-items: center;
  display: flex;
  transition: all 0.277s 0s ease-in-out;
  position: relative;
  letter-spacing: 0.01em;
  position: relative;
  line-height: 1rem;
  justify-content: center;
  cursor: pointer;
}

.status-circle {
  position: absolute;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 2px;
  background: transparent;

  &.small {
    width: 8px;
    height: 8px;
    border-width: 1px;
  }

  &.middle {
    width: 13px;
    height: 13px;
  }

  &.large {
    width: 18px;
    height: 18px;
  }

  &.is-online {
    border-color: var(--body-background-color);
    background: #2BAC76;
  }
}

img.avatar,
div.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}


.s-circle {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
  
  &.auto {
    width: 100%;
    align-self: stretch;
    border-radius: unset;
  }

  &.x-small, &.x-small img {
    min-width: 1.5rem;
    min-height: 1.5rem;
    max-width: 1.5rem;
    max-height: 1.5rem;
    font-size: 0.6rem;
    line-height: 0.5rem;
  }
  &.small, &.small img {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    font-size: 0.625rem;
    line-height: 0.625rem;
  }
  &.middle, &.middle img {
    min-width: 3.75rem;
    min-height: 3.75rem;
    max-width: 3.75rem;
    max-height: 3.75rem;
  
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  &.miro {
    background: #ffcf00;
    color: black !important;
  }
  &.large, &.large img {
    min-width: 4.5rem;
    min-height: 4.5rem;
    max-width: 4.5rem;
    max-height: 4.5rem;
    font-size: 2rem;
    line-height: 2rem;
    svg {
      width: 2rem;
    }
  }
  &.x-large, &.x-large img {
    min-width: 5rem;
    min-height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    font-size: 1.75rem;
    line-height: 1.75rem;
    svg {
      width: 1.75rem;
    }
  }
  
  &.xx-large, &.xx-large img {
    min-width: 8.25rem;
    min-height: 8.25rem;
    max-width: 8.25rem;
    max-height: 8.25rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    svg {
      width: 2.5rem;
    }
  }

  &.xxx-large, &.xxx-large img {
    min-width: 10rem;
    min-height: 10rem;
    max-width: 10rem;
    max-height: 10rem;
    font-size: 3.25rem;
    line-height: 3.25rem;
    svg {
      width: 3.25rem;
    }
  }

  &.default-colors {
    color: #707070;
      background-color: rgba(112, 112, 112, 0.3);
    path {
      fill: #707070;
    }
    .fa-plus {
      font-size: 1.2em;;
    }
  }

  &:hover {
    transition: 0.3s ease-in-out;
  }

  &.has-action  {
    &.active,
    &:hover {
      opacity: 0.75;
    }
    span {
      font-size: 0.525em;
    }
  }
  img {
      vertical-align: middle;
      border-style: none;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      height: auto;
      width: auto;
  }
}
