.linked_in-button {
  width: 33px;
  height: 36px;
  margin-bottom: 6px;
  margin-left: 8px;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;
  // background-color: #fff;

  img {
    width: 100%;
    height: 36.5px;
  }

  &:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }
}

.word-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


@-webkit-keyframes fill {
  to {
    background-size: 100% 0;
  }
}

@keyframes fill {
  to {
    background-size: 100% 0;
  }
}

.link {
  text-decoration: underline;
  border: 0 !important;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: var(--primary) !important;
    font-weight: 600;
    text-decoration: underline;
  }
  svg, img {
    margin-right: 5px;
  }
  &.primary {
    color: var(--primary);
  }
  &.dark {
    color: var(--charcoal-color);
  }
}

.s-button {
  .saving {
    width: 30px;
  }
}


#btn-scroll {
  position: fixed;
  opacity: 0;
  bottom: 20px;
  right: 0;
  font-size: 18px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border: none;
  z-index: 12;
  transform: translate(0, 0);


  svg {
    fill: var(--charcoal-color);
      height: 60px;
    padding: 10px;
    width: 60px;
  }

  &.transition {
    transform: translateX(-45px);
    transition: transform 1s ease 0s;
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    transition: transform 1s ease 0s;
  }
}

.hover-expand {
  @extend .circle;
  padding: 5px 9px;
  background-color: rgba(112, 112, 112, 0.3);
  span {
    margin-left: 5px;
    display: none;
  }

  &:hover {
    border-radius: 200px !important;
    max-width: 210px !important;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.2);

    span {
      display: block;
    }
  }
  
  &:hover:before {
    transition: all 0.5s ease;
    box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
  }
}


.iron-color {
  color: var(--iron-gray-color);
}

.charcoal-color {
  color: var(--charcoal-color);
}

.MuiButton-iconSizeSmall {
  width: 14px;
}

.MuiButtonBase-root.MuiIconButton-sizeSmall.MuiChip-deleteIcon,
.MuiIconButton-root.MuiIconButton-sizeSmall {
  font-size: 14px;
  line-height: 16px;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: none;
  font-family: "europa", sans-serif;

  &.MuiButton-sizeMedium {
    height: 40px;
  }
  &.MuiButton-sizeSmall {
    height: 26px;
    font-size: 14px;
    padding: 4px 6px;
    align-items: center;
    justify-content: center;
  }
  &.MuiButton-sizeLarge {
    height: 40px;
    min-width: 9rem;
  }
  &.MuiButton-fullWidth {
    margin-left: 0;
  }
}

.MuiButton-root.MuiButton-contained {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
}

.MuiButton-outlined {
  &.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium {
    border-width: 1.5px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    height: 32px;
  }
  &.MuiButton-root:hover {
    color: #fff;
  }
  &.MuiButton-outlinedPrimary:hover {
    background-color: var(--primary);
  }
  &.MuiButton-outlinedSecondary {
    border-color: #000;
  }
  &.MuiButton-outlinedError {
    border-color: var(--error-color);
  }
  &.MuiButton-outlinedError:hover {
    color: var(--error-color);
  }
  &.MuiButton-outlinedSecondary:hover {
    background-color: #000;
  }
  &.MuiButton-outlinedSuccess:hover {
    color: var(--success-color);
  }
}
.MuiButtonBase-root.MuiButton-text {

  &.s-link:hover {
    span {
      text-decoration: underline;
    }
    }
}
.MuiButton-startIcon.MuiButton-iconSizeMedium {
  i {
    font-size: 15px
  }
}


.MuiButton-text.MuiButton-textPrimary:hover {
  background-color: rgba(4, 72, 110, 0.18);
}

.MuiIconButton-root.h-button,
.MuiIconButton-root.h-button.dark {
  color: var(--charcoal-color);
}

.MuiButton-contained.MuiButton-containedSecondary {
  color:#fff;
}

.btn-integration {
  width: 100%;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }
  .saving {
    max-width: 30px;
    max-height: 30px;
    path {
      fill: #fff;
    }
  }

  svg:not(:root) {
      overflow: hidden;
  }

  .social-icon {
    max-width: 30px;
  }

  .social-icon,
  span {
    margin: 0 0.269rem;
  }
  &.g-plus:hover,
  &.g-plus {
    background: #E94F3F;
  }

  &.linkedin:hover,
  &.linkedin {
    background: #4C7A90;
    margin-top: 15px;
  }
}

.s-button.arrow {
  display: flex;
	justify-content: space-between;
  .MuiButton-endIcon {
    transform: scale(1);
  }
  &.down svg {
    transform: rotate(180deg);
  } 
  &.up svg {
    transform: rotate(0deg);
  } 
}







