$INCREMENTAL: rgb(76,122,144);
$SEARCH: rgb(216,152,44);
$BREAKTHROUGH: rgb(151,60,118);
$RADICAL: rgb(204,48,75);
$PENDENT: rgb(112, 112, 112);
$LOW: rgb(127,181,160);
$HIGH: rgb(243,157,165);
$MEDIUM: rgb(238,195,0);

.evaluation {
  &.pendente .MuiSlider-valueLabel,
  &.pendente {
    color: $PENDENT;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($PENDENT, 0.3);
    }
  }
  &.radical .MuiSlider-valueLabel,
  &.radical {
    color: $RADICAL;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($RADICAL, 0.3);
    }
  }
  &.pesquisa .MuiSlider-valueLabel,
  &.pesquisa {
    color: $SEARCH;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($SEARCH, 0.2);
    }
  }
  &.breakthrough .MuiSlider-valueLabel,
  &.breakthrough {
    color: $BREAKTHROUGH;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($BREAKTHROUGH, 0.2);
    }
  }
  &.incremental .MuiSlider-valueLabel,
  &.incremental {
    color: $INCREMENTAL;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($INCREMENTAL, 0.25);
    }
  }
  &.media .MuiSlider-valueLabel,
  &.medio .MuiSlider-valueLabel,
  &.medio, &.media {
    color: $MEDIUM;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($MEDIUM, 0.2);
    }
  }
  &.alta .MuiSlider-valueLabel,
  &.alto .MuiSlider-valueLabel,
  &.alto, &.alta {
    color: $HIGH;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($HIGH, 0.3);
    }
   
  }
  &.baixa .MuiSlider-valueLabel,
  &.baixo .MuiSlider-valueLabel,
  &.baixo, &.baixa {
    color: $LOW;
    .MuiSlider-valueLabel,
    &.fill {
      background-color: rgba($LOW, 0.3);
    }
  }
  &.x-large {
    i {
      font-size: 2rem;
      text-align: center;
    }

    .fa-mountain,
    .fa-money-bill {
      font-size: 1.563rem;
    }
    li {
      margin: 0 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &.middle {
    i {
      font-size: 1.25rem;
      text-align: center;
    }
    .fa-mountain,
    .fa-money-bill {
      font-size: 1.125rem;
    }
    li {
      margin-right: 15px;
    }
  }

  &.small {
    i {
      font-size: 0.75rem;
      text-align: center;
      display: block;
    }
    li {
      margin-right: 0.25rem;
    }
  }

  .sub__label {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 300;
    color: #fff;
  }
}

.MuiSlider-root.pendente .MuiSlider-valueLabel {
  span span {
    display: none;
  }
}