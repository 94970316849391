$PATENT:rgb(253, 114, 89);
$ARTICLE: rgb(76, 122, 144);

.form-check.patent {
  .form-check-input,
  .form-check-input:checked {
    background-color: $PATENT;
    border-color:  $PATENT;
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
} 
.form-check.article {
  .form-check-input,
  .form-check-input:checked {
    background-color: $ARTICLE;
    border-color:  $ARTICLE;
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.trends__category {
    &.patent {
      color: $PATENT;
    }
    &.patent.fill {
      background-color: rgba($PATENT, 0.25);
    }

    &.article {
      color: $ARTICLE;
    }

    &.article.fill {
      background-color: rgba($ARTICLE, 0.25);
    }

    &.new {
      color: var(--trends-new-color);
    }
}

.similarity {
  display: flex;
  align-items: center;
  .similarity__level {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #000000;

  }
  .similarity__details {
    &--value {
      font-weight: 300;
    }

    .bar {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .dot {
      /*   opacity: 0.5; */
      position: relative;
      width: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      height: 18px;
      margin-right: 5px;
    }

    .dot:after {
      content: "\A";
      position: absolute;
      background-color: rgba(0, 0, 0, 1);
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--width);
      border-radius: 100px;
    }
  }
}
