.settings__page {
  .data-table-search {
    max-width: calc(min(100vw, #{var(--inner-width)}) * 0.7);;
        }
}

.settings__page {
  #settings-tabs {
    border-bottom: 0;
  }
}

.security-form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.permissions-button {
  margin-left: 15px;
  height: 32px;
  width: 85px;
  border: 1.8px solid #adacac;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 2px;
  color: #736f6f;
}

/* ======================== */
/*   Syed Sahar Ali Raza   	*/
/* ========================	*/
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);



.billing-payment-method {
  .container-card {
    max-width: 721px; 
    min-width: 715px;
  }
  @media (min-width: 0px) {
    .stripe-form {
      .StripeElement {
        width: 90vw;
      }
      .stripe-fields {
        .StripeElement {
          width: 42vw;
        }
      }
    }
  }

  @media (min-width: 900px) {
    .stripe-form {
      .StripeElement {
        width: 400px;
      }
      .stripe-fields {
        .StripeElement {
          width: 185px;
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }
}

#billing,
#security,
#organizationProfile,
#landingPage,
#settingProfile {

  textarea,
  input {
    border-radius: 4px;
    font-weight: 200;


    &:focus {
      background: #f7f7f7;
    }
  }
}

$sidebar-top-padding: calc(var(--navbar-height) + 60px);

.list--organizations {
  overflow-y: auto;
  max-height: 80vh;

  .item {
    display: flex;
    padding-right: 0.25rem;
  }
}

.profile-cover-overlay {
  display: block;
  width: 100%;
  height: 230px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.enterprise__features {
  li {
    cursor: auto;
  }

  p {
    margin: 20px 0;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    a {
      color: var(--primary);
      text-decoration: underline;
      margin-left: 0.25rem;
    }
  }
}

.settings-backup {
  .backup__options > div {
    width: fit-content;
  }
  .backup__options li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .backup-steps {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.55);
      font-weight: 400;
    }
    .check-backup {
      margin-right: 10px;
      color: var(--success-color);
    }
  }

  .outline.primary {
    margin-top: 20px;
    max-width: 120px;
  }
}

.billing__overview {
  display: flex !important;
  flex-direction: column !important;
  color: var(--charcoal-color);
  font-weight: 400;

  a {
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
      color: var(--primary);
    }
  }

  .loading {
    height: 15vh;
  }
  .footer {
    font-size: 1rem;
    line-height: 20px;
    border-top: 1px solid var(--fog-gray-color);
    padding-top: 1.5rem;
    margin-top: 5rem;

    .infos {
      margin: 1.25rem 0;
      a {
        text-transform: lowercase;
      }
    }
  }

  .footer.loading {
    height: 7vh;
  }

  p {
    line-height: 25px;
    font-size: 1rem;
    a, .bold {
      padding: 0 .25rem;
    }
    
  }
}

@media (max-width: 1460px) {
  .color-row {
    .pretty-sm,
    .pretty-sm label {
      font-size: 1em !important;
    }
  }
}


.licenses-options .control-container {
  width: fit-content;
}
.licenses-options {
  .list-group {
    max-height: 240px;
  }
}

.plans-list {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;

  .licenses-options {
    margin: 1rem 0;
  }

  .plan-item {
    position: relative;
    box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 10%);
    margin: 0 0.5rem;

    flex: 0 0 30%;
    max-width: 30%;
  }


  .generic_feature_list {
    display: flex;
    flex-direction: column;
    height: 30vh;

    strong {
      margin-bottom: 10px;
    }

    span {
      width: fit-content;
      font-weight: 200;
      margin-top: 10px;
      border-bottom: 1px dotted black;
    }
  }

  .generic_content {
    border-width: 1px;
    color: var(--charcoal-color);
    border-color: var(--fog-gray-color);
    height: 100%;

    &.selected,
    &:hover,
    &.active {
      border-color: var(--primary);
    }
    button {
      margin-top: auto;
      width: 100%;
    }

    .info {
      min-height: 15vh;

      .price {
        font-size: 1.6rem;
      }

      span {
        font-size: 0.875rem;
        line-height: 1rem;
      }

      p {
        margin: 20px 0;
      }
    }

    .plan__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      span {
        font-size: 20px;
      }

      svg {
        margin-right: 0.5rem;
      }

      .licenses {
        border: 0px;
        font-weight: 200;
        border-bottom: 1px dotted black;
      
      }
      
    }
  }

  .plan-item {
    min-height: calc(min(100vw, #{var(--inner-width)}) * 0.333);
  }

}

.hire__plan-actions {
  display: flex;
  justify-content: space-between;
  margin: 35px 0px;

  a {
    display: flex;
    align-items: center;
    color: var(--primary);
    text-decoration: underline;
  }
}

@media (min-width: 1561px) {
  .plans-list .generic_content .info {
    height: 20vh;
  }
}

@media (max-width: 1920px) {
  .plans-list .generic_content .info {
    height: 20vh;
  }
}


