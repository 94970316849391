.lp-title {
  font-size: 18px !important;
  font-weight: 200 !important;
  margin-right: auto !important;
}

.color-label {
  margin-right: 11%;

  @media screen and (max-width: 1500px) {
    max-width: 13%;
    margin-right: 10%;
  }
}

.social-icon {
  font-size: 25px;
  margin-right: 1%;
}

.upload-label {
  margin-right: 8%;
  display: flex;
  align-self: flex-start;
  max-width: 18%;
}


.upload-button {
  @media screen and (max-width: 1500px) {
    margin-left: 0%;
  }

  margin-left: 1%;
}

.upload-image-video-button {
  @media screen and (max-width: 1500px) {
    margin-left: -3%;
  }

  margin-left: -2%;
}

input[type="color"] {
  padding: 0px 2px !important;
  border: 1.5px solid !important;
  border-radius: 3px !important;
  width: 13% !important;
  background-color: #ffff !important;
}

.video-image-fields {
  width: 79%;
  padding-left: 8%;
}

.video-link {
  width: 100%;

  @media screen and (min-width: 1500px) {
    width: 95%;
    margin-left: 1%;
  }
}

.add-video-image-label {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  max-width: 20%;
}

.social-media-label {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  max-width: 20%;
  margin-right: 10%;
}

.social-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  // border: 1px solid;
}

.social-media {
  width: 100%;
}

.social-miedia-inputs {
  display: block;
  width: 92%;

  @media (min-width: 1500px) {
    width: 90%;
  }
}

.or-label {
  margin-left: 0.5%;

  @media (min-width: 1500px) {
    margin-left: 1%;
  }
}

.save-lp-button {
  margin-left: 5%;
  margin-right: 2%;

  @media (min-width: 1500px) {
    margin-right: 3%;
  }
}

.active-lp-label {
  max-width: 25%;

  @media (min-width: 1500px) {
    max-width: 20%;
  }
}

.switch-active {
  margin-left: 4%;

  @media (min-width: 1500px) {
    margin-left: 7%;
  }
}