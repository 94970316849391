.data-table-search {
  .rdt_Table {
    height: auto;
  }
  
}

// LOADER CONTAINER
.data-table-search>div>div>div {
  background-color: transparent;
}