
.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.navbar-nav {
  min-height: var(--navbar-height);
}


div.navbar-collapse {
  height: 100%;
}


/*
** NAVLINK
*/

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  //give nav it's own compsite layer
  will-change: transform;
  transform: translateZ(0);

  display: none;

  height: 3.125rem;

  box-shadow: 0 -2px 5px -2px #333;
  background-color: #fff;

  color: rgb(70, 70, 70);
  font-weight: bold;

  &__item {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item--active {
    //dev
    color: black;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
  }

  @include for-phone-only {
    display: flex !important;
  }


}
