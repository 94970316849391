.switch label input:checked + i {
  color: var(--primary);
}

/*
** SWITCH
*/


.switch label input[indeterminate="true"] + .bmd-switch-track:after {
  left: calc(80% - 1.25rem);
  background-color: #ffffff;
}

.switch label input:checked[value="false"] + .bmd-switch-track {
  background-position: 0%;
}

.switch label input:checked[value="false"] + .bmd-switch-track:after {
  left: calc(60% - 1.25rem);
  background-color: #949494;
}

.switch label input:checked[value="undefined"] + .bmd-switch-track {
  background-position: 0%;
}

.switch-btn {
  text-align: center;
  will-change: transform;
  border-radius: 16px;
  transition: 0.8s ease all;
  margin-bottom: 0;
  border: 1px solid var(--fog-gray-color);
  position: relative;
  height: 32px;
  background-color: rgba(76, 122, 144, 0.25);
  display: flex;

  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  div {
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 12px;
    transition: all 0.277s 0s ease-in-out;
    i {
      margin-right: 5px;
    }
    span {
      font-weight: 400;
    }
    cursor: pointer;
    &.switch-left,
    &.switch-right {
      color: white;
      background: var(--primary);
    }
  }
}
