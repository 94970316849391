.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
  gap: 4px;
  min-height: 300px;
  max-height: 54vh;

  .no__content {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .MuiButton-text.MuiButtonBase-root.MuiButton-root {
    display: block;
    padding: 0;
    border-radius: 0;
    height: 100%;
  }

  &__item {
    width: 32.33%;
    height: 120px;

    cursor: pointer;
    &_body {
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-in-out;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        &:hover {
          width: 110%;
          height: 110%;
        }
      }
    }
  }
}

.pagination {
  .page-item {
    .page-input {
      width: 40px;
      text-align: center;
      display: inline-block;
    }

    span {
      padding: 6px 10px;
      font-size: 16px;
    }
  }
}
