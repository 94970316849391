/*
** CONNECTION
*/

#connection {
  width: 100vw;
  height: 0vh;
  background-color: rgba(59, 151, 118, 1);
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;
  position: fixed;
  top: -10px;
  z-index: 1000;
}

#connection.offline {
  top: 0px;
  background-color: rgba(233, 79, 63, 1);
  height: 25px;
  transition: all 0.5s ease-out;
 
}

#connection.reconnected {
  top: 10px;
  background-color: var(--byzantine-color);
  height: 25px;
  transition: all 0.5s ease-out;
}

#connection.update {
  top: 0px;
  background-color: var(--primary);
  height: 25px;
  transition: all 0.5s ease-out;
}

#connection {
  top: auto !important;
  bottom: 0 !important;
}
