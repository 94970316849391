.cover-box {
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 33.75rem; //540px

    a {
            text-decoration: none;
    
            &:hover {
                color: inherit;
            }
        }
    .MuiButtonBase-root.MuiButton-text.MuiButton-textInherit,
    .MuiButton-outlinedInherit {
        color: #fff;
    }

    .overlay {
        background: rgba(0, 0, 0, 0.5);
        display: block;
        width: 100%;
        height: 100%;
        transition: 0.5s ease;
        position: absolute;
        backface-visibility: hidden;
    }

    .cover-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top,
        .middle,
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .right,
            .center,
            .left {
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                width: 33.33333333%;
            }

            .left {
                justify-content: flex-start;
            }

            .right {
                justify-content: flex-end;
            }

            .center {
                justify-content: center;
            }
        }
    }

    &.team {
        height: 480px
    }

    .sticker-idea {
        padding: 5px 28px;
        height: 32px;
        align-items: baseline;
    }

    .hasArrow svg {
        display: none;
    }

    .hover-expand {
        min-width: 36px;
        max-width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }

    .circle {
        min-width: 36px;
        max-width: 36px;
        height: 36px;
        font-size: 0.875rem;

        img {
            color: #fff;
        }
    }

    .evaluations-icons li {
        margin: 0 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .grupo-cover-overlay {
        display: block;
        width: 100%;
        height: 480px;
        transition: 0.5s ease;
        backface-visibility: hidden;
    }

    .cover__content {
        transition: 0.5s ease;
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        height: 100%;
        color: white;
        padding-top: var(--cover-content-padding);
        padding-bottom: var(--cover-content-padding);
        display: flex;
        flex-direction: column;

        .activity-count {
            padding: 3px 8px;
            height: 30px;
        }

        .top {
            .left .circle {
                background-color: rgba(112, 112, 112, 0.3);
                margin-right: 10px;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }

        p {
            margin-bottom: 0;
            font-size: 1rem;
            white-space: pre;
        }

        .bottom {
            .right .circle {
                border: 1px solid #fff;
                background-color: transparent;
            }

            .center {
                transition: 0.5s ease;
                opacity: 1;

                img {
                    padding: 3px 4px 6px 4px;
                    height: 20px;
                    width: 20px;
                }

                a {
                    color: #fff;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .middle {
        transition: 0.5s ease;
        opacity: 1;
        display: flex;
        text-align: center;
        color: white;
        overflow: hidden;

        .left {
            flex-direction: column;

            .cover-title {
                text-align: start;
                font-weight: 500;
                font-size: 2em;
                width: 100%;
                overflow-wrap: break-word;
            }

            .cover-subtitle {
                font-weight: 200;
                display: flex;
                align-items: center;
                width: 100%;
                white-space: nowrap;

                .created__at::first-letter {
                    text-transform: lowercase;
                }

                .vertical__bar {
                    margin-bottom: 0.25rem;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .s-circle.evaluation {
        border: 1.5px solid;
        background-color: transparent !important;
    }

    .s-circle.evaluation.pendente {
        color: #fff !important;
    }

    .cover.teams {
        height: 350px;
    }

    @media (max-width: 921px) {
        .cover-content {

            .top,
            .middle,
            .bottom {

                .right,
                .center,
                .left {
                    display: flex;
                    align-items: center;
                    flex: 0 0 auto;
                    width: 100%;
                }
            }

            .middle {
                flex-direction: column;
                justify-content: center;

                .cover-title {
                    justify-content: center;
                }

                .cover-subtitle {
                    text-align: center;
                }
            }
        }
    }

}