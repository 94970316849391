.linear-scale-builder {
    padding: 1rem 0;

    .marker {
        display: flex;
        align-items: center;

        .prefix {
            flex: 0 0 5.33333%;
            color: var(--dark-color);
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            max-width: 8.33333%;
        }

        input {
            flex: 0 0 35.99%;
            max-width: 35.99%;
        }

        .max-char {
            color: var(--dark-color);
            font-size: 12px;
            margin-left: 30px;
        }
    }

    .delimiters {
        padding: 1rem;
        display: flex;
        align-items: center;

        span {
            padding: 0 10px;
        }

        label {
            padding: 0 .5rem 0 0;
        }
    }
}

.s-dymanic-form {
    &.is-invalid {
        box-shadow: 0 2px 1px -1px rgb(233 79 63 / 100%), 0 1px 1px 0 rgb(233 79 63 / 14%), 0 1px 3px 0 rgb(233 79 63 / 100%);
    }

    .draggable-item {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .draggable__list {
        svg {
            transform: rotate(90deg);
        }
    }

    &:hover {
        .draggable__list {
            opacity: 1;
        }
    }

    div[data-rbd-droppable-id="listOptions"] {
        [data-item="draggable"] {
            margin-left: -16px;

            svg {
                opacity: 0;
            }
        }

        [data-item="draggable"]:hover {
            svg {
                opacity: 1;
            }
        }
        }
    .field {
        width: 100%;

        .grid__horizontal {
            width: 100%;
            opacity: 0;

            &.edit {
                opacity: 1;
            }
        }

        [inputype="textinput"] {
            width: 70%;
        }
    }

                                                                                                                                                                                                                                                                .field-builder {
        .field__question {
            .MuiInputBase-input.MuiInput-input {
                padding: 0;
            }
        }

        p.field__question {
            flex-wrap: wrap;
            flex-grow: 1;
            display: flex;
            overflow-wrap: anywhere;
        }
                                                                                                                                }
}