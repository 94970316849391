/*
** CAROUSEL
*/

.carousel-controls {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  max-width: 1905px;
}

.carousel-control-next, .carousel-control-prev {
    height: 50%;
    pointer-events: all;
    transform: translateY(50%);
}

.carousel-control-prev {
  left: calc(var(--sides-padding)/3);
  justify-content: flex-start;

  &-icon {
    width: 15px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.707' height='30.707' viewBox='0 0 15.707 30.707'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0.354 0.354)'%3E%3Cline class='a' y1='15' x2='15'/%3E%3Cline class='a' y1='15' x2='15' transform='translate(15 15) rotate(90)'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.carousel-control-next {
  right: calc(var(--sides-padding)/3);
  justify-content: flex-end;

  &-icon {
    width: 15px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.707' height='30.707' viewBox='0 0 15.707 30.707'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0.354 0.354)'%3E%3Cg transform='translate(-10807.5 -22507.5) rotate(180)'%3E%3Cline class='a' y1='15' x2='15' transform='translate(-10822.5 -22537.5)'/%3E%3Cline class='a' y1='15' x2='15' transform='translate(-10807.5 -22522.5) rotate(90)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

@media (max-width: 1460px) {
  .carousel-control-next {
    right: 35px !important;
  }
  .carousel-control-prev {
    left: 35px !important;
  }
}

