/*Comment List styles*/
.comments__list {
  .empty__comment {
    justify-content: center;
    padding: 3rem;
  }
  .panel__default {
    .comment__footer {
      margin: 15px 0;
    }
  }
  .main__comment {
    padding: 16px 24px;
    background-color: var(--empty-state-color);
    border-radius: 0px 0px 4px 4px;
    .comment__lg {
      display: flex;
      .panel__default {
        display: flex;
        align-items: flex-end;
        width: 100%;
      }
    }
    .panel__body {
      box-shadow: none;
      background-color: transparent;
      width: 100%;
      padding: 0;
    }
    .panel__body .comment__user {
      color: var(--charcoal-color);
      font-weight: 700;
    }
    .comment__footer {
      margin: 0;
    }
  }
  .comment__edited {
    color: var(--iron-gray-color);
    margin-left: 0.25rem;
    font-size: 11px;
    padding: 0.40625rem 0.5rem;
  }
  .comment__lg {
    position: relative;
    width: 100%;
    min-height: 1px;
  }
  .comment__sm {
    position: relative;
    width: 80%;
    min-height: 1px;
  }
  header {
    display: flex;
    justify-content: space-between;
  }
  article {
    display: flex;
    justify-content: flex-end;
    .comment__user {
      font-weight: 400;
      line-height: 16px;
      color: var(--primary)
    }
    .comment__author {
      margin-right: 20px;
    }
    .comment__action {
      cursor: pointer;
      align-items: center;
      display: flex;
    }
    .content-right {
      display: flex;
      justify-content: flex-end;
    }
  }
  article.reply-box,
  article.reply {
    .comment__author {
      margin-top: 20px;
    }
  }
}
.comments__list .panel .panel-heading {
  padding: 4px 15px;
  position: absolute;
  border: none;
  /*Panel-heading border radius*/
  border-top-right-radius: 0px;
  top: 1px;
}
.comments__list .panel__body {
  box-shadow: 2px 2px 8px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 1em;
  background-color: #fff;
}
.comments__list .panel .panel-heading.right {
  border-right-width: 0px;
  /*Panel-heading border radius*/
  border-top-left-radius: 0px;
  right: 16px;
}
.comments__list .panel .panel-heading .panel__body {
  padding-top: 6px;
}
.comments__list figcaption {
  /*For wrapping text in thumbnail*/
  word-wrap: break-word;
}
/* Portrait tablets and medium desktops */
@media (min-width: 768px) {
  .comments__list .arrow:after,
  .comments__list .arrow:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }
  .comments__list .panel.arrow.left:after,
  .comments__list .panel.arrow.left:before {
    border-left: 0;
  }
  /*****Left Arrow*****/
  /*Outline effect style*/
  .comments__list .panelarrow.left:before {
    left: 0px;
    top: 30px;
    /*Use boarder color of panel*/
    /* border-right-color: inherit; */
    border-width: 16px;
    border-right-color: transparent;
  }
  /*Background color effect*/
  .comments__list .panel.arrow.left:after {
    left: 1px;
    top: 31px;
    /*Change for different outline color*/
    border-right-color: #ffffff;
    border-width: 15px;
  }
  /*****Right Arrow*****/
  /*Outline effect style*/
  .comments__list .panel.arrow.right:before {
    right: -16px;
    top: 30px;
    /*Use boarder color of panel*/
    border-left-color: inherit;
    border-width: 16px;
  }
  /*Background color effect*/
  .comments__list .panel.arrow.right:after {
    right: -14px;
    top: 31px;
    /*Change for different outline color*/
    border-left-color: #ffffff;
    border-width: 15px;
  }
}
.comments__list .comment__post {
  margin-top: 6px;
  position: relative;
  z-index: 1;
}

.comments__list .comment__post {
  a {
    color: var(--primary);
    text-decoration: underline;
  }
    .s-jodit-text {
    min-height: 90px !important;
  }
  .jodit_workplace {
    min-height: 90px !important;
    border-radius: 4px;
    margin-top: 0.25rem;
  }
  .jodit_wysiwyg {
    min-height: 90px !important;
  }
  .jodit.jodit-container:not(.jodit_inline),
  .jodit-container:not(.jodit_inline) {
    border: 0
  }
}
