@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

$font-family: "europa",sans-serif;

:root {
  --font-family: "europa",sans-serif;
  --prussian-blue-color: #04486E;
  --sky-blue-color: #91D1DA;
  --blue-color: #5cacb7;
  --air-force-color: #4C7A90;
  --prussian-blue-color-2: #04486E2E;
  --black-blue-color: #16232A;

  --seafoam-green-color: #7FB5A0;
  --subtle-green-color: #B3D9CA;
  --charcoal-color: #444444;
  --iron-gray-color: #707070;
  --fog-gray-color: #E6E6E6;
  --empty-state-color: #EFEFEF;
  --byzantine-color: #973c76;
  --flamingo-color: #F39DA5;

  --salmon-color: #FD7259;
  --lobster-color: #CC304B;
  --error-color: #E94F3F;
  --disabled-color: rgba(68, 68, 68, 0.3);

  --success-color: #3B9776;

  --tuscany-yellow-color: #EEC300;
  --dijon-yellow-color: #D8992C;

  --trends-patent-color: rgb(253, 114, 89);
  --trends-article-color: rgb(76, 122, 144);
  --trends-new-color: #973c76;

  --body-background-color: rgba(247, 247, 247, 1); 
  --primary: var(--prussian-blue-color);
  --waiting-color: #f5f5f5;

  --navbar-height: 80px;
  --inner-width: 1905px;

  --sidebar-background-color: #21313D;
  --sidebar-icon-color: #b7c0cd;
  --sidebar-icon-active-color: #fff;
  --sidebar-shrink-width: 100px;
  --header-padding-X: 60px;
  --header-padding-Y: 36px;
    --header-height: 152px;
  --dropdown--bg-color: rgba(4, 72, 110, 0.18);

  --cover-content-padding: 30px;
  --sides-padding: 140px;

  --card-content-padding: 20px;
  --card-padding: 14px;

  --width-sidebar: 250px;
  --width-sidebar-workspaces: 55px;
  --firefox-scrollbar-width: thin;
  --light-gray-color: #dbdbdb;
  --dark-color: rgb(112, 112, 112);
  --dark-color-2: rgb(68, 68, 68);
  --form-color: var(--charcoal-color);
  --default-hover: hsla(0, 0%, 60%, 0.2);

  --width-activity-count: 3.75rem;
  --form-border-radios: 4px;

  --firefox-scrollbar-thumb-color: -moz-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  --safari-scrollbar-thumb-color: -webkit-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  --chrome-scrollbar-thumb-color: linear-gradient(to right,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
}

html {
  /* Firefox Scrollbar */
  scrollbar-color: rgba(46, 46, 46, 0.8) rgba(0, 0, 0, 0);
  scrollbar-width: var(--firefox-scrollbar-width);
}

body {
  font-family: $font-family !important;
  background: var(--body-background-color);
  overflow-x: hidden;

  b, strong {
    font-weight: 600;
  }
 
  a,
                a:hover,
                a:focus {
      color: var(--primary);
    transition: all 0.3s;
  }
  p {
    font-family: $font-family;
    // font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    word-break: break-word;
  }

  ol, ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  button {
    background-color: transparent;
  }
  
  i,
  span {
    display: inline-block;
  }
}

#root {
  display: flex;
  background: var(--body-background-color);
  min-height: 100vh;
}

/*
** Scrollbar
*/

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
  overflow: visible;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: -moz-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  background: -webkit-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  background: linear-gradient(to right,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: -moz-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  background: -webkit-linear-gradient(left,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
  background: linear-gradient(to right,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 42%,
      rgba(46, 46, 46, 0.8) 100%);
}

/*
** RESET
*/

* {
  margin: 0;
  padding: 0;
  // word-break: break-all;
}

li {
  list-style: none;
}

ul {
  margin: 0px;
}

textarea {
  resize: none;
}

#saving {
  z-index: 10000;
}

$len: 242.776657104492px;
$time: 1.6s;

@keyframes trendsLoaderAnim {
  12.5% {
    stroke-dasharray: $len * 0.14, $len;
    stroke-dashoffset: -$len * 0.11;
  }

  43.75% {
    stroke-dasharray: $len * 0.35, $len;
    stroke-dashoffset: -$len * 0.35;
  }

  100% {
    stroke-dasharray: $len * 0.01, $len;
    stroke-dashoffset: -$len * 0.99;
  }
}

.trends-loader-container {
  .trends-loader {
    .outline {
      stroke: #04486d;
      stroke-dasharray: $len * 0.01, $len;
      stroke-dashoffset: 0;
      animation: trendsLoaderAnim $time linear infinite;
    }

    .outline-bg {
      stroke: #04486d;
    }
  }
}

.trends_icon_hover {
  .trends-loader {
    .outline {
      stroke: white;
    }

    .outline-bg {
      stroke: white;
    }
  }
}


#mobile_bottom_bar {
  display: none;
  justify-content: center;

  @include for-phone-only {
    display: flex;
  }
}

.myBottomBar {
  box-shadow: 0px 0px 10px 1px #ccc;
  border-radius: 15px;
}

.myBottomBar button {
  padding-left: 0.7rem;
  background-color: white;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  border-width: 1px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 0.8rem;
  border-right: 1px solid black;
  border-color: #ccc;
  border-width: 1px;
  color: rgb(70, 70, 70);
  font-weight: bold;
  text-transform: none;
  cursor: pointer;
}

.myBottomBar button:first-child {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  margin: 0;
  padding-left: 1rem;
  border-right: 1px solid black;
  border-color: #ccc;
}

.myBottomBar button:last-child {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding-right: 1rem;
  border-right: 0px;
}

.s-jodit,
.s-jodit-text {

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: '1px solid #dadada',
  }
  img {
    max-width: 100%;
  }
}

.s-jodit-text:not(.jodit_inline) {
  font-family: $font-family !important;
}

.jodit_context_menu {
  font-family: $font-family !important;
}

.jodit_toolbar pre {
  font-family: $font-family !important;
}

.jodit_filebrowser {
  font-family: $font-family !important;
}

.jodit_toolbar_popup-inline>div {
  font-family: $font-family !important;
}

.jodit_text_icons .jodit_tabs .jodit_tabs_buttons>a {
  font-family: $font-family !important;
}

.jodit_text_icons.jodit_dialog_box .jodit_dialog .jodit_button,
.jodit_text_icons.jodit_dialog_box .jodit_dialog .jodit_dialog_header a,
.jodit_text_icons .jodit_toolbar>li>a {
  font-family: "europa", sans-serif !important;
}

.tooltip__matrix {
  height: 50px;
  width: 50px;

 
  // visibility: visible !important;
  // opacity: 1 !important;
 
  .tooltip__matrix--inner {
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999999999;
  }
}