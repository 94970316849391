/*
** FORM WIZARD
*/

/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Montserrat);


.wizard-fieldset {
  /* background: white; */
  border: 0 none;
  border-radius: 3px;
  /* box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4); */

  box-sizing: border-box;
  width: 100%;
  margin: 0 0%;
  text-align: initial;
}

@media (min-width: 768px) {
  .wizard-fieldset {
    /* Sem TipBox */
    /* width: 80%;
        margin: 0 10%; */

    /* Com TipBox */
    width: calc(100% - 150px);
    margin: 0 75px;
  }
}

.form.form-control.is-valid, .was-validated .form.form-control:valid,
.form.form-control:focus,
.form.form-control {
  background-color: var(--body-background-color);
  border: 1px solid var(--form-color);
  border-radius: 4px !important;
  background-image: none !important;
}

.form {
  &.form-control.is-invalid, .was-validated &.form-control:invalid {
    border-color: var(--error-color) !important;
  }
  &.form-control.is-invalid:focus, .was-validated &.form-control:invalid:focus {
    border-color: var(--error-color) !important;
    box-shadow: 0 0 0 0.25rem rgb(233 79 63 / 25%) !important;
  }

  &.form-control.is-valid:focus, .was-validated &.form-control:valid:focus {
    border: 1px solid var(--form-color);
    box-shadow: none;
}
}



.form-control:disabled {
  background-color: #e9ecef !important;
} 

.form.form-control:focus {
  color: #212529;
  background-color: transparent;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.input-search-nav {
  max-width: 250px;
}

.s-form.modal-edit {
  .permission__item {
    .permission__radio {
      color: var(--charcoal-color);

      .permission__icon {
        margin-left: 10px;
        width: 1.6rem;
      }
  
      .bold {
        padding-right: 0.5rem;
      }
  
      &:hover {
        color: var(--iron-gray-color);
      }
    }
                                }

  .tags__label,
  .cover__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .format-platform-name {
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .fa-trash {
    font-size: 14px;
  }

  .box-heading {
    padding: 5px 0;
  }

  .platform-type {
    .MuiFormControlLabel-root {
      align-items: flex-start;
    }
  }
  .platform__type--idea {
    i {
      width: 1rem;
      margin-right: .5rem;
    }
    .form-check {
      margin-right: 10px;
    }
  }
  .platform-type {
    .radio p, label {
      color: var(--charcoal-color);
      font-weight: 400;
      i {
        width: 1rem;
        margin-right: .5rem;
      }
    } 
  }
  .upload-files {
    .file-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid var(--fog-gray-color);
      .content-left {
        display: flex;
        align-items: center;
        a:hover {
          text-decoration: underline;
          color: var(--primary);
          font-weight: 600;
        }
      }
                                }
    .saving {
      width: 30px;
    }
  }
}

.s-form {

  .s-label-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .challenge__types {
    [data-key="0"] {
      margin-bottom: 2px;
    }

    .radio {
      label {
        margin: 0;
      }
    }
    

    .challenge__format {
      display: flex;
      width: 100%;
      align-items: center;
      color: var(--charcoal-color);
      font-size: 1rem;

      &:hover {
        color: var(--iron-gray-color);
      }

      .type {
        display: flex;
        align-items: center;
        font-weight: 600;

        i,
        svg {
          margin-left: 10px;
          width: 1.6rem;
        }

        span {
          margin: 0 0.5rem 0 0;
        }
      }
    }
  }

}

.s-input {
  width: 100%;
}

#billing,
#security,
#organizationProfile,
#settingProfile {
  .s-input,
  .s-textarea {
    position: relative;
  }
}

.box__info--small {
  font-size: .875rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}


input[type='file']{
  display: none;
}

.MuiAutocomplete-listbox {
  .autocomplete-item {
    display: flex;
    align-items: center;
  }
}

.s-autocomplete {
  .autocomplete-list {
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .autocomplete-label-3 {
    padding-right: 5px;
  }
  .MuiAutocomplete-option > div {
    display: flex;
  }
  .MuiAutocomplete-tag,
  .MuiAutocomplete-inputRoot .MuiButtonBase-root.MuiChip-root {
    border-radius: 4px;
    max-width: max-content;
    background-color: var(--dropdown--bg-color);
    display: flex;
    align-items: center;
    gap: 5px;
    border: 0;
  }

  .MuiAutocomplete-inputRoot {
    .is-fixed {
      .MuiAutocomplete-tag {
        background-color: var(--fog-gray-color);
        color: var(--iron-gray-color);
      }
    }
  }
}


