/* CHARTS */
.chart {
  &-200 {
    height: 200px;
  }

  &-300 {
    height: 300px;
  }

  &-400 {
    height: 400px;
  }

  &-500 {
    height: 500px;
  }
}

.chart-grid {
  display: flex;
  align-content: center;
  align-items: center;
  flex-flow: row;
  justify-content: space-between;
}

$chart-spacing: 2; // percent
$chart-margin-bottom:  calc($chart-spacing/100);

$col-chart-base: calc((100 - ($chart-spacing)) / 3);
$col-1: calc(floor($col-chart-base) / 100);
$col-2: calc(ceil($col-chart-base * 2) / 100);

.col-1x1 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: $chart-margin-bottom;
}

.col-1-1x2 {
  flex: 0 0 $col-1;
  max-width: $col-1;
  margin-bottom: $chart-margin-bottom;
}

.col-2-1x2 {
  flex: 0 0 $col-2;
  max-width: $col-2;
  margin-bottom: $chart-margin-bottom;
}

.col-1-2x1 {
  flex: 0 0 $col-2;
  max-width: $col-2;
  margin-bottom: $chart-margin-bottom;
}

.col-2-2x1 {
  flex: 0 0 $col-1;
  max-width: $col-1;
  margin-bottom: $chart-margin-bottom;
}

.col-2x2 {
  flex: 0 0 $col-2;
  max-width: $col-2;
  margin-bottom: $chart-margin-bottom;
}

.col-3x3 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: $chart-margin-bottom;

  @media (max-width: $break-small) {
    flex: 0 0 49%;
    max-width: 49%;
  }
  @media (min-width: $break-small) and (max-width: $break-medium-) {
    flex: 0 0 $col-1;
    max-width: $col-1;
  }
  @media (min-width: $break-medium) and (max-width: $break-large-) {
    flex: 0 0 $col-1;
    max-width: $col-1;
  }
  @media (min-width: $break-large) {
    flex: 0 0 $col-1;
    max-width: $col-1;
  }
}

[class^="col-"].card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}



.trendingChart {
  min-height: 300px;
  height: 100%;

  .circle-avatar {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
}

/*
    Chart: 
        Filters         
*/

.select-combo {
  width: 300px;
  display: inline-block;
  margin: 10px;
}

.select-combo {
  width: 250px;
  overflow: hidden;
}
.select-combo select {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: none !important;
  border-radius: 0px !important;
  background-position: 205px center;
  width: 270px;
  height: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding: 13px 20px 13px 12px;
  color: #171717;
  text-indent: 0.01px;
  text-overflow: "";
}
.select-combo select select::-ms-expand {
  display: none;
}

.jumbo-filters {
  padding: 5px !important;
  background: #f0f0f0 !important;
}

.column-name {
  color: #000;
  font-weight: bold;
}

/*
    Shape of Science
*/

.outer-box {
  margin: 0px auto;
}

.sidenav-filters {
  height: 100%;
  margin-top: 55px;
  max-width: 250px;
}

.sidemenu-container {
  overflow: hidden;
  display: flex;

  .sidebar2 {
    width: 300px;
    position: relative;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 10;
    background: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .sidebar2 {
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  .sidebar2:hover {
    &::-webkit-scrollbar-thumb {
      background: var(--firefox-scrollbar-thumb-color);
      background: var(--safari-scrollbar-thumb-color);
      background: var(--chrome-scrollbar-thumb-color);
    }
  }

  .sidebar2.active {
    left: 0;
  }

  .sidebar2 select {
    overflow-x: scroll;
  }

  .container {
    margin-left: -250px;
  }

  #dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #dismiss:hover {
    background: black;
    color: #fff;
    fill: #fff;
  }
}
.shapeOfScience {
  &Container {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    & > span {
      width: 100%;
    }
  }
  &__tooltip {
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    transition: opacity 0.5s;
    max-width: 25vw;
    min-width: 25vw;
    min-height: 100vh;
    max-height: 100vh;
    z-index: 5;
    right: 0px;
    top: 0px;
  }
  &__tooltip__container {
    padding: 0.835rem;
  }
  &__tooltip__header {
    padding-bottom: 1rem;
  }
  &__tooltip__year {
    display: inline;
  }
  &__tooltip__value {
    display: inline;
    float: right;
  }
  &__tooltip__body {
    display: block;
    padding-bottom: 0rem;
  }
  &__tooltip__title {
    display: block;
    padding-bottom: 1rem;
  }
  &__tooltip__authors {
    display: block;
    padding-bottom: 1rem;
  }
  &__tooltip__abstract {
    display: block;
  }
}

.articleInfo {
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Roboto";
  transition: all 0.5s ease;

  &__header {
    background-color: #fff;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;

    &_container {
      padding: 2rem 4rem;
      max-height: 85%;
      overflow: auto;
    }

    &--title {
      font-weight: bold;
      padding-top: 1rem;
      font-size: 20px;
    }

    &--property {
      font-weight: 400;
      padding: 2rem 0 1rem 0;
    }

    &--btnClose {
      float: right;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: -20px;
    }

    &--abstract {
      font-weight: 300;
    }
  }

  &__details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12.5%;
    min-width: 225px;
    height: auto;
    z-index: 2;
    background-color: rgb(213, 219, 231);
    right: 47%;
    padding: 2rem;

    &--property {
      font-weight: 700;
    }

    &--value {
      font-weight: 300;
      padding-bottom: 1rem;
    }

    &--btnFullText {
      border: 1px solid black;
      border-radius: 50px;
      text-align: center;
      min-width: 100px;
      padding: 0.25rem 0.5rem;
      font-weight: bold;
      margin-top: 1rem;
    }

    .relevance {
      margin-top: 1rem;
      width: 100%;
      height: 3px;
    }

    .bar {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dot {
      /*   opacity: 0.5; */
      position: relative;
      width: 15%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 100px;
    }

    .dot:after {
      content: "\A";
      position: absolute;
      background-color: rgba(0, 0, 0, 1);
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--width);
      border-radius: 100px;
    }
  }

  &__tooltip {
    background-color: white;
    padding: 0.75rem;
    box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 1);

    &__arrow {
      width: 0px;
      height: 0px;
      background-color: transparent;
      position: absolute;
      filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#fff')";
      filter: url(#drop-shadow);
      -webkit-filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
      filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));

      //top - min left
      // left: 7px;

      //top - min right
      // right: 7px;

      //right - min top
      // top: 7px;

      //right - min bottom
      //bottom: 7px;

      //bottom - min left
      // left: 7px;

      //bottom - min right
      // right: 7px;

      //left - min top
      // top: 7px;

      //left - min bottom
      //bottom: 7px;

      &.up {
        top: -10px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid white;
      }
      &.left {
        left: -7px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid white;
      }
      &.down {
        bottom: -10px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid white;
      }
      &.right {
        right: -7px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid white;
      }
    }

    :before1 {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid black;
      left: -8px;
      top: 70px;
    }

    &--title {
    }
    &--footer {
      margin-top: 2rem;
      width: 100%;
      display: inline-block;

      &--property {
        font-weight: 300;
        display: inline;
        color: gray;
      }

      &--value {
        display: inline;
      }

      &--date {
        display: inline;
      }

      &--similarity {
        float: right;
        margin-left: 1rem;
      }
    }
  }
}
