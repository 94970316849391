.sticker {
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.01em;
  line-height: 1rem;
  padding: 6px 16px 4px;
  min-width: 80px;
  border-radius: 4px;
  height: 30px;
  text-align: center;

  &.primary {
    color: var(--primary) !important;
  }

  &.agente {
    color: rgba(76, 122, 144);
    background-color: rgba(76, 122, 144, 0.25);
  }

  &.gray {
    color: var(--iron-gray-color);
  }
  &.sponsor,
  &.purple {
    color: rgb(151, 60, 118);
    background-color: rgba(151, 60, 118, 0.2);
  }

  &.dark {
    color: black;
  }
  &.moderador,
  &.yellow {
    color: rgb(238, 195, 0);
    background-color:rgba(238, 195, 0, 0.2);
  }

  &.lg {
    min-width: 150px;
    height: 37px;
    font-size: 18px;
  }
}
