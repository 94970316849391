
.jodit_wysiwyg {
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: underline;
    color: var(--primary);
  }
}


.s-jodit {
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;

  .jodit-container:not(.jodit_inline) .jodit-workplace {
    background-color: transparent;
  }
  .jodit-react-container {
    position: relative; // return
  }
  .jodit-status-bar {
    padding-right: calc(1.5em + 0.75rem);
  }

  &.Mui-error .jodit.jodit-container:not(.jodit_inline) {
    border-color: #E94F3F
  }
  .jodit {
    &.jodit-container:not(.jodit_inline) { 
      width: 100%;
      border-radius: var(--form-border-radios);
      border: 1px solid var(--form-color);
      .jodit-status-bar {
        background-color: var(--body-background-color) !important;
      }
  
      .jodit-status-bar .jodit-status-bar-link {
        display: none;
      }
     
  
    } 
  
    .jodit-workplace+.jodit-status-bar:not(:empty) {
      border-top: 1px solid var(--form-color);
    }
  
    &.jodit-container:not(.jodit_inline) .jodit_workplace {
      background-color: rgba(0, 0, 0, 0);
    }
  
    .jodit-workplace {
      padding: 0.25rem;
    }
  
    .jodit-wysiwyg {
      a {
        color: var(--primary);
        cursor: pointer;
        text-decoration: underline;
      }
  
      li {
        list-style: auto;
      }
    }
  
    &.jodit-container .jodit-wysiwyg ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  
    &.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
      padding: 5px !important;
    }
  
    .jodit_toolbar .jodit_toolbar_btn-break {
      border-top: 1px solid var(--form-color);
    }
  
    &.jodit-container .jodit-wysiwyg ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  
    ul.jodit_toolbar {
      border: 0;
    }
  
    .jodit_toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  
    .jodit_statusbar {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 0;
    }
  
    &.jodit-container .jodit_toolbar,
    &.jodit-container .jodit_statusbar {
      background-color: var(--body-background-color);
      border: 0;
    }
  
    &.jodit_toolbar .jodit_toolbar_btn-break {
      border-top: 1px solid var(--form-color);
    }
  
    &.s-jodit-text:not(.jodit_inline) .jodit_workplace {
      border: 0;
      border-bottom: 1px solid var(--form-color);
    }
  
    &.jodit-container .jodit-wysiwyg li {
      display: list-item;
      text-align: -webkit-match-parent;
      list-style-type: inherit;
    }
  
  }
}
