//Classes

.d-none {
  display: none !important;
}

.scrollableY-auto {
  overflow-y: auto !important;
}

.scrollableY-hidden {
  overflow-y: hidden !important;
}

.text-underline {
  text-decoration: underline !important;
}

.word-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  position: relative;
  text-align: center;
}

.circle {
  color: #fff;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-light {
  font-weight: 100;
}

.bg-blue {
  background-color: var(--primary);
}

.bg-white {
  background-color: white;
}

.bg-green {
  background-color: var(--seafoam-green-color) !important;
}

.bg-senno {
  background-color: var(--primary) !important;
}

.color-primary {
  color: var(--primary);
}

/*
** UTILS
*/

.blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -ms-filter: blur(10px);
  -o-filter: blur(10px);
  /* FF doesn't support blur filter, but SVG */
  filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
  filter: "progid: DXImageTransform.Microsoft.Blur(PixelRadius = '10')";
  filter: blur(10px);
}

.opacity-05 {
  -webkit-filter: opacity(0.5);
  -moz-filter: opacity(0.5);
  -ms-filter: opacity(0.5);
  -o-filter: opacity(0.5);
  filter: opacity(0.5);
}

.border-bottom-light {
  border-bottom: 1px solid var(--fog-gray-color);
}

.border-bottom-light-opacity {
  border-bottom: 1px solid rgba(230,230,230, 0.5);
}

[scrollable] {
  max-height: 200px;
  overflow-x: hidden;
}

[scrollable-md] {
  max-height: 350px;
  overflow-x: hidden;
}

[scrollable-lg] {
  max-height: 600px;
  overflow-x: hidden;
}

.fa-1_5x {
  font-size: 1.5em;
}

.flex-basis-0 {
  flex-basis: 0% !important;
}

.vh-50 {
  max-height: 50vh !important;
}

.w-90 {
  width: 90% !important;
}

.first-letter-capitalize {
  text-transform: lowercase !important;

  &::first-letter {
    text-transform: capitalize !important;
  }
}

.text-capitalize {
  text-transform: capitalize !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mx-3,
.mr-3 {
  margin-right: 1rem !important;
}

.mx-4,
.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.pl-0 {
  padding-left: 0;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-0 {
  padding: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.h-20px {
  height: 20px !important;
}

.h-30px {
  height: 30px !important;
}

.h-45px {
  height: 45px !important;
}

.h-68px_i {
  height: 68px !important;
}

.ml-15px {
  margin-left: 15px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.ml-30px {
  margin-left: 30px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mr-15px {
  margin-right: 15px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-60px {
  margin-bottom: 60px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-15p {
  margin-top: 9%;
}

.p-0px {
  padding: 0px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pv-0px {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ph-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.text-cover {
  font-size: 96px !important;
}

.text-justify {
  text-align: justify !important;
}

.text-title {
  font-size: 30px !important;
}

.subheader {
  margin-top: 60px;
}

.text-header {
  font-size: 20px !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-quote {
  font-size: 20px !important;
  font-style: italic !important;
}

.text-large-body {
  font-size: 14px !important;
  font-weight: bold !important;
}

.text-charcoal {
  color: var(--charcoal-color) !important;
}

.text-dark {
  color: #000000 !important;
}

.text-danger {
  color: var(--error-color) !important;
}

.text-body {
  font-size: 14px !important;
}

.uppercase {
  text-transform: uppercase;
}

.shadow-none {
  box-shadow: none !important;
}

.w-40 {
  width: 40% !important;
}

.heart-red {
  color: #e8384f !important;
}


.text-blue {
  color: var(--primary) !important;
}

.text-green {
  color: var(--success-color);

  [class*="icon-"] {
    border-color: var(--success-color) !important;
  }
}

.text-yellow {
  color: var(--tuscany-color);

  [class*="icon-"] {
    border-color: var(--tuscany-color) !important;
  }
}

.word-break-normal {
  word-break: normal !important;
}

.border-bottom-dark {
  border-bottom: 1px solid var(--charcoal-color);
}

ul.ul-list {
  margin-left: 15px;
  list-style-type: disc;

  li {
    font-weight: bold;
    list-style-type: disc;
  }

  ul {
    margin-left: 15px;
    list-style-type: circle;

    li {
      font-weight: normal;
      list-style-type: circle;
    }
  }
}

[invalid] * {
  color: var(--error-color) !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.underline {
  text-decoration: underline !important;
}

.text-14px {
  font-size: 14px !important;
}

.text-20px {
  font-size: 20px !important;
}

.text-link {
  cursor: pointer;
}

.bold {
  font-weight: 700 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 500 !important;
}