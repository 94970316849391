$inner-width: 1905px;

.page-new-home {
  width: 100%;
}

.box-heading .infos-left {
  display: flex;
  flex-direction: column;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000;
  }
  
  .sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
  }
}



/**Header Box Image*/

.box-heading {
  display: flex;
  justify-content: space-between;
}

.box-heading {
  display: flex;
  padding: 30px 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 31px;
  .see-more {
    text-decoration: none;
    font-weight: 600;
    color: var(--primary);
  }
  .see-more:hover {
    text-decoration: underline;
  }
}

.btn--ellipses {
  color: var(--iron-gray-color) !important;
  background: transparent !important;
}

.challenge__deadline {
  min-width: 140px;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  padding: 6px 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  height: 50px;
  line-height: 15px;
  margin-bottom: 10px;

  i {
    margin-right: 10px;
  }

  span {
    font-weight: 200;
    font-size: 11px;
  }

  &--total {
    display: flex;
    flex-direction: column;
    line-height: 25px;
    text-transform: uppercase;

    span {
      font-weight: 200;
    }
  }

  .challenge__clock {
    display: flex;
  }
}

//font-size = calc( [font-size-min] + ( ([font-size-max] - [font-size-min]) / [max-width-pag] ) * 100vw



@media (min-width: 1538px) {
  .rollover,
  .box-photo,
  .lazy {
    max-height: calc(min(100vw, #{$inner-width}) * 0.09);
    min-height: calc(min(100vw, #{$inner-width}) * 0.09);
  }

  .hover-item {
    font-size: calc(10px + (4 / 1200) * 100 * 1vw);
  }

  .hover-item .bottom {
    margin-top: -2.5rem !important;
  }
  
}

@media (max-width: 1538px) {
  .rollover,
  .box-photo,
  .lazy {
    max-height: calc(min(100vw, #{$inner-width}) * 0.13);
    min-height: calc(min(100vw, #{$inner-width}) * 0.13);
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .rollover,
  .box-photo,
  .lazy {
    max-height: calc(min(100vw, #{$inner-width}) * 0.22);
    min-height: calc(min(100vw, #{$inner-width}) * 0.22);
  }
}

@media (max-width: 768px) {
  .rollover,
  .box-photo,
  .lazy {
    max-height: calc(min(100vw, #{$inner-width}) * 0.38);
    min-height: calc(min(100vw, #{$inner-width}) * 0.38);
        }
}