.QA-question {
  outline: none !important;
  background: #E6E6E6 !important;
  border: none !important;
  padding: 10px 10px 10px 10px !important;
  border-radius: 5px !important;
  width: 100%;
  margin-bottom: 3%;
  
}

.QA-title {
  font-weight: 700;
  margin-bottom: 3%;
  margin-right: 2%;
}

.QA-button {
  background: transparent !important;
  border: 1px solid !important;
  color: #000000 !important;
}

.QA-button:disabled {
  background: transparent !important;
  border: 1px solid  #4444444D !important;
  color: #4444444D !important;
}

.QA-buttons-space {
  margin-left: 10px;
  margin-right: 10px;
}

.QA-button:hover {
  background: #000000 !important;
  border: 1px solid !important;
  color: #fff !important;
}

.QA-question::placeholder {
  color: #00000066;
}

.QA-question:focus-visible {
  border: 0px;
}

.QA-answer-highlight {
  background-color: #D5DCE7;
  font-weight: bold;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 4px;
}

.QA-relevance {
  font-size: 16px;
}

.QA-buttons-group {
  display: flex;
  align-content: stretch;
  align-items: center;
  justify-content: center;
  margin-bottom: 6%;
}

.QA-source-link {
  font-size: 16px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 2%;
}

.QA-note {
  font-size: 14.5px;
  color: #0000008C;
  margin-bottom: 5%;
}

.QA-infos {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2%;
}

.QA-relevance-info {
  display: flex;
  background: #fff;
  padding-top: 3%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 1%;
  border-radius: 5px;
  margin-bottom: 5%;

  p {
    font-weight: 600;
    font-size: 13px;
  }
}

.QA-generated-questions {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 3%;
  background: transparent;
  border: 0px;
}

.QA-see-results {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: stretch;
  color: #04486E;

  p {
    margin-bottom: 0px !important;
  
  }
}

.QA-checkbox-all {
  margin-bottom: 6%;
}

.QA-tab-icon {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;

  span {
    margin-left: 10px;
  }
}

.QA-saved-result-icons {
  margin-left: 25px; 
  color: #000;
}

.QA-saved-list {
  background: transparent;
  border: none;
  border-bottom: 0.5px solid #ddd;
  margin-bottom: 4%;
}