// Google Integration

.picker.picker-dialog {
  z-index: 1301
}


.MuiPaper-root.MuiDialog-paper {
  background-color: var(--body-background-color);
  &.MuiDialog-paperFullScreen .MuiDialogContent-root {
    overflow-y: auto;
    .cover__group {
      .hover-item {
        height: 11.375rem; // 182px;
      }
    }
  }
}

.MuiTypography-root.MuiDialogTitle-root {
  padding-right: 0;
    padding-left: 0;
  font-family: var(--font-family);
}

.miro-embedded-board {
  width: 100%;
  height: 100%;
}

.MuiModal-root.s-modal-sm,
.MuiModal-root.s-modal-xs {
  .MuiTypography-root.MuiDialogTitle-root { 
    padding-top: 0;
  }

  .MuiButton-text {
    &.MuiButtonBase-root.MuiButton-root {
      font-weight: 700;
    }
  }
}

.MuiDialog-paper.MuiDialog-paperFullScreen {
  .MuiDialogContent-root {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .MuiDialogContent-root {
    height: 100vh;
    overflow-x: hidden;
  }

  .modal__footer {
    margin: 30px 0;
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
      border-width: 2px;
    }
                                                                                                                                }
  .modal__header {
    .MuiIconButton-root {
      width:  90px;
      height: 90px;
    }
  }
}

.modal__feedback {
  p {
    font-weight: 400;
    color: var(--charcoal-color);
    a.underline:hover {
      color: var(--primary);
    }
  }
}

.MuiDialog-container {
  // width: 515px;
  .modal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

    &_nav {
      display: flex;

      .disabled {
        opacity: 0.1;
      }
    }
  }

}

.select-image {
  background: rgba(0, 0, 0, 0.01);
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

.expanded .medal-type-list-icon,
.retract .medal-type-list {
  transform: scale(0);
  transition: all 0.1s ease;
}

.canvas {
  border: 20px solid var(--iron-gray-color);
  border-bottom: 1px solid var(--iron-gray-color);
  border-top: 1px solid var(--iron-gray-color);
  border-right: 1px solid var(--iron-gray-color);
  &.active {
    border: 20px solid var(--primary);
    border-bottom: 1px solid var(--primary);
    border-top: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
  }
}

.modal__plan {
  .container-card {
    width: 100%;
  }

  .stripe-form {
    .StripeElement {
      width: calc(23vw - 48px);
    }
    .stripe-fields {
      .StripeElement {
        width: calc(((23vw - 48px)/2) - 15px);
      }
    }

  }

  .modal__footer .MuiGrid-container, 
  .stripe-buttons {
    justify-content: flex-end;
    .s-button {
      margin-left: 10px;
      min-width: 110px;
    }
  }
  
  
  .info {
    display: flex;
    margin: 30px 0;

    span {
      font-weight: 200;
    }

    .info__updated {
      display: flex;
      flex-direction: column;
    }

    .info__current {
      display: flex;
      flex-direction: column;
      color: var(--iron-gray-color);
    }
  }

  .angle {
    display: flex;
    align-items: center;
    margin: 0 30px;

    .angle-left {
      fill: none;
      stroke: #000;
    }
  }
}

.modal__org,
.modal__invite {
  .input-field {
    height: 180px !important;
  }
}

#modal-downgrade {
  header {
    padding: 4px 0;
  }

  .info__remain {
    strong {
      font-weight: 800;
      margin: 0 0.25rem;
    }
  }


  .contactus {
    margin-top: 30px;

    a {
      max-width: 30%;
    }

    label {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.modal__approve {
  .approve__actions {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
  .approve__actions.pendente {
    justify-content: space-between;
  }
  .approve__actions.aprovada,
  .approve__actions.reprovada {
    .s-button {
      cursor: auto;
    }
  }
  .approve__actions.aprovada {
    .s-button {
      background-color: rgba(127, 181, 160, 0.3);
    }
  }
  .approve__actions.reprovada {
    .s-button {
      background-color: rgba(199, 18, 49, 0.12);
    }
  }
}

.modal__evaluations {
  .list {
    margin: 20px 0;
    min-height: 10vh;
    max-height: 50vh;
    li {
      padding: 0.5rem;
      align-items: center;
    }
  }
}

.modal__evaluation {
  .evaluation__actions {
    display: flex;
    color: rgba(0, 0, 0, 0.75);
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    span {
      margin: 0 0.25rem;
    }
  }
  .form__evaluation {
    button {
      border-radius: 4px;
    }
  
    .mine__evaluation {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .criteria__value {
        display: flex;
        align-items: center;
        margin: 12px 0;
        .criteria__name {
          font-weight: 400;
          margin-right: 0.25rem;
        }
      }
  
      .title {
        color: var(--charcoal-color);
        font-size: 30px;
        padding: 20px 0;
      }
  
      button {
        height: 35px !important;
        font-size: 14px !important;
      }
    }

    .slider__container {
      min-height: 100px;
      align-items: center;
      margin: 0;
    }
  
    .form__type {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .list {
        margin: 0;
      }
  
      .icons {
        display: flex;
        justify-content: center;
  
        .circle {
          width: 30px;
          height: 30px;
          background-color: var(--fog-gray-color);
          margin: 5px 2rem;
  
          &.active {
            background-color: var(--primary);
  
            i {
              color: #fff;
            }
  
            svg {
              fill: #fff;
  
              path {
                stroke: #fff;
              }
            }
          }
  
          i {
            color: var(--iron-gray-color);
          }
  
          svg {
            fill: var(--iron-gray-color);
  
            path {
              stroke: var(--iron-gray-color);
            }
          }
        }
      }
    }
  
    .evaluation__slider {
      width: 50%;
  
      strong {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
  
    .card-body {
      background: #f3f3f1;
      padding: 0;
  
      hr {
        margin: 0;
      }
  
      .question-left {
        font-weight: 200;
        color: black;
        line-height: 25px;
        width: 100%;
        margin-right: 30px;
      }
  
      .multiple__options {
        padding: 1.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
  
      .multiple__options--sub {
        display: flex;
        align-items: center;
        padding-left: 25px;
        line-height: 25px;
  
        span {
          font-weight: 200;
        }
  
        ul.questions-inline {
          display: flex;
          width: 45%;
  
          li {
            width: 40%;
          }
        }
      }
  
      .evaluation__item {
        border-bottom: 1px solid var(--fog-gray-color);
        padding: 1.25rem; // 20px
        ul.questions-inline {
          display: flex;
          width: 45%;
  
          li {
            width: 40%;
          }
        }
  
        .question {
          margin-left: 20px;
          margin-right: 30px;
          line-height: 25px;
        }
      }
    }
  }
}

.tags__group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .tag {
    border: 1px solid var(--primary);
    border-radius: 50px;
    color: var(--primary);
    text-decoration: none;
    padding: 0.08em 1.5em;
    margin: 2.5px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
  }
}

.modal__template {
  color: rgba(0, 0, 0, 0.75);

  section {
    h6 {
      font-weight: 600;
    }
    &.summary, &.examples, &.figure, &.workflow {
      display: inline-block;
    }
    &.summary {
      white-space:  pre-line;
    }
    &.examples {
      ul {
        padding-left: revert;
      }
      li {
        list-style: disc;
      }
    }
  }

}

.modal__documents {
  .infos,
  .trends__category {
    line-height: 16px;
    color: rgba(0, 0, 0, 0.55);
  }
  .document__item {
    display: flex;
    flex-direction: column;
    span, p {
      margin-bottom: 12px;
    } 
  
    .document__title {
      line-height: 20px;
      font-size: 1rem;
      color: #000;  
      font-weight: 500;
      a {
        color: var(--iron-gray-color); 
      }
      a:hover {
        color: var(--primary)
      }
    }
  }
}

