$sidebar-top-padding: calc(var(--navbar-height) + 20px);

#sidebar--workspaces {
  height: 100vh;
  width: var(--width-sidebar-workspaces);
  background-color: var(--black-blue-color);
  ul.workspaces-list {
    max-height: 85vh;
    overflow-y: auto;
  }

  .s-workspace.MuiChip-outlined {
    color: #fff;
    background-color: var(--primary);
  }
  .s-workspace.MuiChip-filled {
    color: rgba(0, 0, 0, 0.6);
    background-color: #fff;
    box-shadow: inset 0 -2px rgba(0, 0, 0, 0.05);
  }
}

.header.show .title {
  margin-left: -3rem;
}

@media (max-width: 1536px) {
  .header.show .title {
    margin-left: 0;
  }
}

#sidebar {
  a {
      color: #fff;
      text-decoration: none;
    }
  ul {
    padding-left: 0;
    margin-top: 1rem;
    list-style: none;
    li {
      color: #fff;
      i {
        width: 35px;
      }
    }
  }
  padding-bottom: 30px;
  width: calc(var(--width-sidebar) - var(--width-sidebar-workspaces));
  background: var(--sidebar-background-color);
  height: 100vh;
  overflow-y: auto;
  /* Firefox Scrollbar */
  scrollbar-width: var(--firefox-scrollbar-width);

  .nav__features {
    li {
      display: flex;

      .btn-add {
        color: #fff;
        background: #28a98f;
        align-self: center;
        margin-left: 20px;
        padding: 10px;
        font-size: 0.9em;
      }
    }

    &_counter {
      display: inline-block;
      border-radius: 10px;
      padding: 3px 10px;
      background: var(--primary);
      color: #fff;
      font-size: 0.8rem;
    }
  }

  .divisor {
    display: block;
    width: 80%;
    height: 1px;
    background: rgba(#b7c0cd, 0.4);
    margin: 30px 0 30px 10%;
  }
}

@media (max-width: 1460px) {
  .header.show .title {
    margin-left: -1rem;
  }
}

#sidebar a:focus {
  outline: auto 5px -webkit-focus-ring-color;
  outline: auto 2px #35619e;
}

#sidebar .nav__features_counter {
  margin-left: 10px;
}

#sidebar ul li {
  margin-bottom: 2px;
  font-size: 1em;
  min-width: 25px;
  width: 100%;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 20px;
    &:hover,
    &.active {
      border-left: 1px solid white;
      background: rgba(255, 255, 255, 0.05);
      color: white;
      font-weight: 600;
    }
  }


}

#sidebar:hover ul li {
  text-align: left;
}


#sidebar ul ul a {
  padding: 10px !important;
}


#sidebar ul li.active>a {
  color: var(--sidebar-icon-active-color);
}

a[data-toggle="collapse"] {
  position: relative;
}

#sidebar ul ul.collapse a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #ececec;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  #sidebar:hover {
    margin-left: 0 !important;
  }

  #sidebar ul li a {
    padding: 10px 0 10px 30px;
  }

  #sidebar ul li a span {
    font-size: 0.85em;
  }

  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }

  #sidebar ul ul a {
    padding: 10px !important;
  }

  #sidebar ul li a i {
    font-size: 1.3em;
  }

  #sidebar {
    margin-left: 0;
  }

  #sidebarCollapse span {
    display: none;
  }
}

.sidebar__sennoLogo {
  width: 3rem;
  height: 3rem;
  background-color: red;
  background: url('/assets/images/brand_logo_full_white.png') no-repeat center center;
  background-size: cover;
  margin: 1.875rem auto;
  /* for IE 8 & 9 */
  // -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#000')";
  // /* For IE 5.5 - 7 */
  // filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#000')";
  -webkit-filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0));
  filter: drop-shadow(1px 2px 2px black);
  opacity: 0.2;
  transition: opacity 0.1s;
}

.sidebar__sennoLogo:hover {
  opacity: 1;
}

.s-appBar {
  a {
    font-size: 1rem;
  }
  .Mui-selected {
    font-weight: 600;
  }
  a.MuiTab-textColorSecondary {
    color: var(--charcoal-color);
  }
}
