/*
** PROGRESSBAR
*/

a.tile-ideia-progressbar {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 25%;
  height: 40px;
  top: 10px;
  left: 10px;
  padding: 8px 5px;
}

/*progressbar*/

#progressbar,
.progressbar {
  margin: 0 75px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  display: flex;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{10 - $i};
      }
    }

    &.bt-item:before {
      content: "";
      width: 100%;
      height: 1px;
      background: rgba(68, 68, 68, 0.3);
      position: absolute;
      top: 30px;
      right: 50%;
      z-index: 0;
    }

    &.bt-item:first-child:before {
      width: 0px;
    }

    a {
      color: rgba(68,68,68, 0.7);
      transition: all 0.3s ease-in-out;
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(68,68,68, 0.3);
      border-radius: 50%;
      margin-top: 20px;
      background: var(--body-background-color);

      &:after {
        content: "";
        transition: all 0.3s ease-in-out;
        width: 0px;
        height: 0px;
      }
    }

    &.active,
    &.current {
      a {
        color: var(--primary);
      }

      &.bt-item:before {
        background: var(--primary);
      }

      .item {
        background: var(--primary);
        border: none;
      }
    }

    &.current {
      .item {
        background: var(--primary);

        &:after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }
}

// #progressbar li, .progressbar li {
//     list-style-type: none;
//     color: black;
//     text-transform: uppercase;
//     font-size: .75em;
//     width: calc(100%);
//     float: left;
//     position: relative;
// }
// @media (max-width: 768px) {
//     .progressbar li {
//         display: none;
//         width: 100%;
//     }
//     .progressbar li.active.current {
//         display: block;
//         width: 100%;
//     }
// }
// #progressbar lia:before {
//     content: counter(step);
//     counter-increment: step;
//     width: 50px;
//     line-height: 50px;
//     display: block;
//     font-size: 10px;
//     color: #333;
//     /* background: white; */
//     background: url('http://localhost:3000/images/wizard-step.svg');
//     border-radius: 3px;
//     margin: 0 auto 5px auto;
// }
/*progressbar connectors*/
/* #progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 23px;
    z-index: -1;
    box-shadow: 0 0 5px 1px rgba(180, 180, 180, 0.4);
} */
// #progressbar li:after {
//     content: '';
//     /* width: calc(100% - (12.96px)); */
//     /* left: calc(-50% + 6.48px); */
//     width: calc(100% - (44px));
//     left: calc(-50% + 22px);
//     height: 1px;
//     background: gray;
//     position: absolute;
//     top: 24px;
//     z-index: -1;
// }
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #27ae60;
  color: white;
}

/*
** SPINNER
*/

.lds-ripple-container {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 55px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #a4a4a4;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

//LOADER

.page-loader {

  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loader-logo {
    text-align: center;
    margin-bottom: 20px;

    svg {
      width: 8rem;
    }

    img {
      width: 75%;
    }
  }

  .loader-bar {
    width: 160px;
    background-color: #eee;
    height: 3px;
    border-radius: 1em;
    overflow: hidden;
    // transform: skewZ(10em);
    position: relative;
    z-index: 1;

    .loader-indicator {
      background-color: var(--primary);
      width: 50px;
      height: 100%;
      border-radius: 10px;
      animation: loader 1s ease-in-out infinite;
    }
  }
}

@keyframes loader {

  0%,
  100% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(130px);
  }
}

.loading__wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  >div {
    position: relative;
    transition: transform 0.15s ease-in-out;

    &:hover {
      transform: scale(1.15);
      z-index: 1;
    }

    &:nth-child(1) {
      background: transparent;
    }

    >svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
    }
  }
}

@keyframes loader {

  0%,
  100% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(130px);
  }
}

.loading {
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.loading::after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent);
  animation: loading 1.5s infinite;
  top: 0;
}

.card-title.loading {
  width: 10rem;
  height: 1rem;
  overflow: hidden;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}