.language__options {
  .list-group-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.s-menu {
  .MuiListItemText-root {
      height: 34px;
      align-items: center;
      display: flex;
    }
  
    .MuiListItemText-multiline {
      align-items: flex-start;
      flex-direction: column;
    }
  .MuiListItemIcon-root,
  .MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.75);
    &.inactive {
      opacity: 0.38;
    }
  }
}
