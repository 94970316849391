.box__page--info {
  text-align: center;
}


@media (max-width: 480px) {
  .right.has-tablet {
    display: block;
  }
}

.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  &.MuiList-padding {
    padding: 0;
  }
  

  .list-item {
    text-align: center;
    gap: 8px;
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
  }

  .item-description {
    text-align: center;
    max-width: 5rem;
  }
}

.list {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;

  li, .list-item {
    display: flex;
    align-items: flex-start;
    flex: 1;
    border-bottom: 1px solid var(--fog-gray-color);
    padding-bottom: 1rem;
    padding-top: 1rem;

    .content-text {
      font-weight: 400;
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.55);
      
      .link {
        width: fit-content;
        display: inline-block;
      }
    }

    .container-content {
      margin-left: 20px;
      width: 85%;

      .title {
        font-weight: 600;
        margin-bottom: 0.5rem;
        display: inline-flex;
        align-items: baseline;
        color: var(--charcoal-color)
      }


      span {
        font-size: 14px;
      }
    }
  }
}

ul.members__list {
  margin: 30px 0;

  .s-circle.evaluation {
    background-color:  transparent !important;
  }

  .right,
  ul.right {
    li {
      margin: 0.2rem;
      border: none;
    }

    a {
      margin: 0.2rem;
    }
  }

  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.3rem 0;
    min-height: 80px;
    border-bottom: 1px solid var(--fog-gray-color);

    width: 100%;

    .member__info {
      margin-left: 0.5rem;
      max-width: 80%;
      display: flex;
      flex-direction: column;

      .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        overflow: hidden;
        font-weight: 600;
        cursor: pointer;
      }

      .name:hover {
        color: var(--primary);
        text-decoration: underline;
      }

     
    }
  }

  .evaluations-icons .s-circle.small {
    font-size: 1rem;
  }
}

.right.has-tablet {
  display: flex;
  align-items: center;

  .sticker-idea {
    margin-right: 10px;
  }
}

.title-list {
  font-weight: 200;
  margin: 20px 0px 20px 0px !important;
  display: flex;
  align-items: center;

  a {
    margin-left: 15px;
  }
}

.search__page {
  .cover {
    display: flex;
    height: 230px;
    background: var(--primary);
    justify-content: center;
    color: white;
  }

  .results {
    display: flex;
    align-items: center;
    font-size: 20px;

    a {
      text-decoration: underline;
    }

    span {
      padding: 0 0.5rem;
    }
  }
}

// Cards and Covers

.hover-item,
.cover__content {     
  .activity-count {
    color: #fff;
    align-items: center;
    font-size: 0.875rem;
    justify-content: center;
    display: flex;
    transition: all 0.277s 0s ease-in-out;
    position: relative;
    letter-spacing: 0.01em;
    position: relative;
    line-height: 1rem;

    i,
    svg {
      margin-right: 5px;
    }
  }

  .activity-count {
    padding: 2px 6px;
  }

  .activity-count.active,
  .activity-count.has-action {
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
    }
  }
}

.platform__page, .idea__page {
  .main-tabs .lds-ripple-container {
    min-height: 50vh;
  }
  .related-tabs .lds-ripple-container {
    min-height: 10vh;
  }
  .main-tabs, .related-tabs {
    justify-content: center;
  }
  .first-section {
    .summary {
      margin: 1rem 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1.75rem;
      line-height: 1.75rem;
    }
    .main-action {
      display: flex;
      justify-content:center;
      button {
        order: 1;
        flex-grow: 0;
        font-size: 1rem;
      }
    }
    .main-action,
    .tags__group {
      margin: 2.5rem 0;
      .tag {
        border: 0;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.75);
        padding: 0.08em 1.5em;
        margin: 2.5px;
        font-weight: 400;
        background-color: #fff;
        font-size: 14px;
        height: 32px;
        &:hover {
          color: var(--primary);
          background-color: rgba(4, 72, 110, 0.18);
        }
      }
    }
  }
}

.profile__page {
  .profile-infos {
    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 180px;
    }
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
   
      .created-at {
        font-style: normal;
        font-weight: 400;
        margin: 0.75rem 0;
      }
    }
  }

  @media (max-width: 990px) {

    .block {
      padding-left: 0;
    }

    .profile-infos {
      flex-direction: row;
      align-items: center;
      .s-circle.avatar {
        width: 8.25rem;
        height: 8.25rem;
        font-size: 2.5rem;
        line-height: 2.5rem;
      
      }
    }
  }
}

.settings__page, .profile__page {
  .main-container {
    display: flex;
  
  }

  @media screen and (max-width: 990px) {
    .main-container {
      display: block;
    }
  }
}

.attachments-list {
  .rdt_TableCell {
    padding: 10px 0;
  }
  [data-column-id="size"] {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.55);
  }

  [data-column-id="name"] {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.75);
  }

  

  .attachment__name  {
    display: flex;
    align-items: center;
  
    .circle {
      min-height: 40px;
      max-height: 40px;
      min-width: 40px;
      max-width: 40px;
      background-color: rgba(112, 112, 112, 0.3);
      i {
        color: #707070;
      }
    }
    span {
      margin-left: 5px;
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.not__found--page {
  .not__found--container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .error {
    font-size: 200px;
    color: #000;
    line-height: 200px;
    font-weight: 400;
  }
  
  .text {
    color: #000;
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .bt-back span {
    margin-left: 10px;
    font-weight: 300;
  }
}


.s-grid-page {
  .title-list {
    font-weight: 700;
  }
}

.footer__info {
  display: flex;
	justify-content: center;
	align-items: flex-end;
	text-align: center;
	font-weight: 200;
  	margin-bottom: 3rem;
	p{
		font-weight: 400;
    color: gba(0, 0, 0, 0.55);
		line-height: 1rem;
		font-size: 14px;
	};
	b{
		font-weight: bold;
	};
	a {
		text-decoration: underline;
	};
	a:hover {
		color: var(--primary);
	}
}