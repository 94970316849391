/*
** LIST GROUP
*/

.list-group-item :first-child {
  margin-right: 0px !important;
}

.list-group {
  li,
  .list-group-item {
    font-weight: 400;
    width: 100%;
    border-radius: 0;
    padding: 10px 20px;
    text-align: left;
    margin: 0;
    align-items: center;
    display: flex;
    white-space: nowrap;
    border: 0;
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--dropdown--bg-color);
      transition: all .3s linear;
      cursor: pointer;
    }
  }
}