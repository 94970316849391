/*
** NUMBER
*/
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

/*
** PLACEHOLDER
*/
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

/*
** SWITCH
*/
.switch label input:checked+.bmd-switch-track:after {
  background-color: var(--primary);
}

.switch label input:disabled+.bmd-switch-track::after {
  background: #bdbdbd;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

input {
  &.form-control:focus {
    background-color: var(--body-background-color);
    background-size: 100% 100%,100% 100%;
    transition-duration: .3s;
    box-shadow: none;
    border: 0;
  }
  &.form-control.is-valid, .was-validated &.form-control:valid,
  &.form-control {
    z-index: 0;
    border: 0;
    background-color: var(--body-background-color);
    background: bottom no-repeat,50% calc(100% - 1px);
    background-size: 0 100%,100% 100%;
    border: 0;
    background-image: linear-gradient(0deg,var(--primary) 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0);
  }
  &.form-control.is-invalid,
  &.form-control.is-invalid:focus, .was-validated &.form-control:invalid:focus {
    background-image: linear-gradient(0deg,var(--error-color) 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0);
    background-position: bottom;
    background-size: 100%;
    box-shadow: none;
  }
  .was-validated &.form-control:valid:focus {
    border:0;
    box-shadow: none;
  }
}

.MuiFormControlLabel-root:hover {
  color: var(--iron-gray-color);
}

.s-select {
  .MuiMenu-list  {
    max-height: calc(100vh - 500px);
  }
}
.s-select.select__form--builder {
  width: 20vw;
}
.select__form--builder .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
}
.select__form--builder .MuiSelect-select.MuiSelect-outlined ,
.s-menu-item.select__form--builder {
  align-items: center;
  display: flex;
  .menu__label {
    padding: 0 15px;
  }
  .grouped {
    width: 100%;
    hr {
      margin: 0;
    }
  }
}

.s-select.selectable {
  .item-details {
    display: none;
  }
}

.s-jodit {
  .s-status {
    position: absolute;
    right: 0;
    margin-top: -45px;
    margin-right: 5px;
  }
}

.s-status {
  svg {
    color: var(--success-color);
  }
}

.s-autocomplete.invite {
  .MuiInputBase-root {
    min-height: 15vh;
    align-items: flex-start;
  }
}
.s-autocomplete,
.s-select,
.s-input {
  .MuiSvgIcon-colorSuccess.MuiSvgIcon-fontSizeSmall {
    font-size: 15px;
  }
  .MuiOutlinedInput-input,
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    padding: 9px 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--charcoal-color);
  }
  .MuiOutlinedInput-root {
    padding: 0 14px 0 0;
  }
}

.MuiTypography-root {
  font-family: var(---font-family);
}

.MuiPopover-paper .MuiList-root {
  max-height: 50vh;
}

// Checkbox

.form-check-input:checked {
  background-color: var(--primary);
  border-color:  var(--primary);
}
.form-check-label,
.form-check-input {
  cursor: pointer;
}
.form-check-label:hover,
.form-check-input:hover ~ .form-check-label {
  color: var(--iron-gray-color);
}
.form-check-input:disabled ~ .form-check-label {
  cursor: auto;
}

.s-check .saving,
.form-check .saving {
  max-width: 30px;
}





