/*
** TOOLTIPS
*/

.tooltip__foam {
  // top: 10px; /* Defines the spacing between tooltip and target position */
  .tooltip-inner {
    background-color: #fff;
    color: #333;
    padding: 16px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    width: 100%;
    height: 100%;
  }
  .inner__group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .graph__content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .year {
        color: rgba(0, 0, 0, 0.55);
        letter-spacing: 0.01em;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .inner__article {
    color: #000;
    background-color: rgba(255, 255, 255, 1);
    width: 240px;
    height: 116px;
    .article__infos {
      color: rgba(0, 0, 0, 0.55);
      letter-spacing: 0.01em;
      font-size: 12px;
      line-height: 16px;
      padding: 8px 0;
    }
  }
  .article__title,
  .title {
    color: #000000;
    font-weight: 400;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .group__title {
    i {
      font-size: 3px;
      margin:5px;
    }
    display: flex;   
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
  // .arrow,
  // .arrow::before {
  //   position: absolute;
  //   width: 8px;
  //   height: 8px;
  //   background: inherit;
  // }

  // .arrow {
  //   visibility: hidden;
  // }

  // .arrow::before {
  //   visibility: visible;
  //   content: '';
  //   transform: rotate(45deg);
  // }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 0.875rem; //14px
  }
  &.secondary  {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: var(--charcoal-color)
    }
  }
  &.primary {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: #fff;
      color: var(--charcoal-color)
    }
  }
}

// Tour

.MuiTooltip-popper.tour {
  .MuiTooltip-tooltip {
    padding: 1rem;
  }
  &.secondary a {
    color: #fff;
  }
}

// Idea Sticker

.MuiTooltip-popper {
  .MuiTooltip-tooltip.box-shadow {
    filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
  }
  .MuiTooltip-tooltip.small {
    max-width: 10vw;
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip.sticker {
    font-size: 0.875rem; //14px
    color: rgba(0,0,0,0.75);
  }
  &.pendente  {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: var(--fog-gray-color);
    }
  }
  &.aprovada  {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: var(--success-color);
    }
  }
  &.reprovada  {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: var(--error-color);
    }
  }
  &.badges  {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: var(--sky-blue-color);
    }
  }
  &.sponsorships {
    .MuiTooltip-arrow::before, .MuiTooltip-tooltip {
      background-color: #fff;
      color: var(--flamingo-color);
    }
  }
}

.search-popper {
  margin-left: -12px !important;
  z-index: 2;
}

