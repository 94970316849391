/*
** CHIPS
*/

.MuiChip-root.s-chip {
  font-size: 13px;
  font-weight: 500;
  line-height: 29px;
  height: 33px;
  border-radius: 5px;
  border: 0;
  &.s-workspace {
    width: 36px;
    height: 36px;
    overflow: visible;
    .MuiChip-label {
      overflow: visible;
    }
  }

  &.MuiChip-outlined {
    cursor: pointer;
  }
  
  &.MuiChip-filled {
    cursor: auto;
  }
}


// Item while dragging
.draggable-dragging {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  z-index: 10;

  * {
    -webkit-touch-callout: none;
    user-select: none;
  }

  label,
  p {
    text-align: left;
  }
}

// Replicate styles for the dragging state

$select-item-border-radius: 2px !default;
$select-item-gutter: 5px !default;
$select-item-padding-vertical: 2px !default;
$select-item-padding-horizontal: 5px !default;
$select-item-font-size: 0.9em !default;
$select-item-color: #08c !default; // pale blue
$select-item-bg: #f2f9fc !default;
$select-item-border-color: darken($select-item-bg, 10%) !default;
$select-item-hover-color: darken($select-item-color, 5%) !default; // pale blue
$select-item-hover-bg: darken($select-item-bg, 5%) !default;
$select-item-disabled-color: #333 !default;
$select-item-disabled-bg: #fcfcfc !default;
$select-item-disabled-border-color: darken(
  $select-item-disabled-bg,
  10%
) !default;

.Select-value {
  &.draggable-dragging {
    background-color: $select-item-bg;
    border-radius: $select-item-border-radius;
    border: 1px solid $select-item-border-color;
    color: $select-item-color;
    display: inline-block;
    font-size: $select-item-font-size;
    line-height: 1.4;
    margin-left: $select-item-gutter;
    margin-top: $select-item-gutter;
    vertical-align: top;

    // common
    .Select-value-icon,
    .Select-value-label {
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      float: left;
    }

    // label
    .Select-value-label {
      border-bottom-right-radius: $select-item-border-radius;
      border-top-right-radius: $select-item-border-radius;
      cursor: default;
      padding: $select-item-padding-vertical 0 $select-item-padding-vertical
        $select-item-padding-horizontal;
    }

    // icon
    .Select-value-icon {
      cursor: pointer;
      border-bottom-left-radius: $select-item-border-radius;
      border-top-left-radius: $select-item-border-radius;
      border-right: 1px solid $select-item-border-color;

      // move the baseline up by 1px
      padding: ($select-item-padding-vertical - 1)
        $select-item-padding-horizontal ($select-item-padding-vertical + 1);

      &:hover,
      &:focus {
        background-color: $select-item-hover-bg;
        color: $select-item-hover-color;
      }
      &:active {
        background-color: $select-item-border-color;
      }
    }
  }
}