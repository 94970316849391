.accordion.evaluation {
  .card {
    box-shadow: none;
  }
  .card-header .accordion-collapse span {
    color: #fff;
  }
  .MuiTypography-root,
  span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.75);
  }

  &.active {
    .card-header {
      background-color: var(--primary);
    }
  }

  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      margin: 0;
    }
    .MuiFormControlLabel-root:not(:last-child) { 
      width: 100%;
    }
  }
  .card-header {
    background-color: #aaaaa9;
    height: 40px !important;
    padding: 0 20px !important;

    >a {
      color: white;
      justify-content: space-between;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 100%;
      height: 100% !important;
      text-align: initial;

      &.collapsed {
        >svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .collapse {
    transition: all 0.45s ease-in-out;
  }
}


