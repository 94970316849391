.preview-box {
  width: 100%;
  height: 0;
  border: 0.5px solid #a7a7a7;
  border-bottom: none;
  border-radius: 3px 3px 0px 0px;
  padding-left: 10px;
  padding-top: 11px;
  opacity: 0;
  margin-bottom: 10px !important;
  display: flex;
  transition: all 0.3s linear;
  z-index: 0;

  p {
    margin-left: 10px;
  }

  .premain__content {
    padding-top: 1px;
    padding-bottom: 1px;
    width: 87.5%;
    height: auto;
    overflow: hidden;
    border-radius: 0px 5px 5px 0px;
  }
}

.preview-box.transition {
  opacity: 1;
  margin-bottom: -7px;
  height: auto;

  .premain__content {
    background-color: #ebe8e8;
  }
}

.preview-title {
  font-size: 16px;
  font-weight: bold;
}

.preview-image.transition {
  justify-content: center;
  // width: 90px;
  // height: 90px;

  label {
    max-width: 75px;
    max-height: 70px;
    min-width: 75px;
    min-height: 70px;
    width: auto;
    height: auto;
    border-radius: 4px 0px 0px 4px;
  }
}